<template>
    <v-col
    cols="12"
    lg="12"
    md="12"
    sm="12"
    class="text-center"
    >
        <v-simple-table class="tbld">
            <template v-slot:default>
            <thead>
                <tr class="trr">
                <!-- <th class="text-center">
                    №
                </th> -->

                <th class="text-center mr-5">
                    QEYDİYYAT KODU
                </th>
                <th class="d-hide"></th>
                <th class="text-center">
                    HƏDİYYƏ
                </th>
                <th class="d-hide"></th>
                <th class="text-center">
                    TİRAJ
                </th>
                </tr>
            </thead>
            <br>
            <tbody class="index-tbody">
                <tr v-for="item in items">
                    <td>{{ item.couponCode}}</td>
                    <td class="d-hide"></td>
                    <td>{{ item.present }}</td>
                    <td class="d-hide"></td>
                    <td>{{ item.draw }}</td>
                </tr>
                
            </tbody>
            </template>
        </v-simple-table>
        <!-- <div class="text-center mt-7">
                <v-pagination
                v-model="page"
                :length="length"
                color="lime darken-4"
                @input="changepage"
                ></v-pagination>
            </div> -->
    </v-col>
</template>
<script>
import axios from "axios";
import moment from "moment";
import store from "../../store/index";
  export default {
    data: () => ({
      items: [],
      itms: [],
      showitms:[],
      pr1:[],
      pr2:[],
      pr3:[],
      prizetype: 0,
      page:1,
      length:1,
      type:"1"
      
    }),
    methods: {
    //   date_format(k){
    //     return k ? moment(k).format('DD.MM.Y HH:mm:ss') : '';
    //   },
      date_format(k){
        return k ? moment(k).format('DD.MM.Y') : '';
      },
    },
    created(){
      axios.get(store.getters.getUrl+'winner/allpublic')
          .then(response => {
            if(response.data.success){
              this.items = response.data.value
              
            }
          })

    },
    computed:{
    }
}
</script>
<style>
@import url('../../assets/css/custom.css');
</style>