<template>
    <v-app-bar
        elevation="24"
        color="#E14924"
        class="appbar"
    >
        <img src="@/assets/images/Group.png" alt="Shah Gold" class="logosh">
        <v-spacer></v-spacer>
        <div class="hidden-sm-and-down" style="max-height: 48px;">
            <v-btn 
                v-scroll-to="'#head'"
                to="#head"
             class="header_link" text>ƏSAS SƏHİFƏ</v-btn>
            <v-btn
                href="/rules"
             class="header_link" text>
             LOTEREYA ŞƏRTLƏRİ</v-btn>
            <v-btn 
                v-for="(item, index) in items"
                :key="index"
                v-scroll-to="item.to"
                :to="item.to"
             class="header_link" text>{{item.title}}</v-btn>
            <!-- <div class="d-inline-block">
                <v-select
                v-model="lang"
                @change="handleChange"
                class="header_link text-uppercase"
                style="width:80px;"
                background-color="#0B0909"
                color="#fff"
                item-color="indigo"
                :items="['az','ru']"
                label="DİL"
                solo
                dark
                ></v-select>
            </div> -->
            <v-btn
                :to="'/login'"
                v-if="!isLogin"
             class="header_link" text>
             GİRİŞ/QEYDİYYAT</v-btn>
            <v-btn
                :to="'/profil'"
                v-if="isLogin"
             class="header_link" text>ŞƏXSİ KABİNET</v-btn>
             <v-btn
                v-if="isLogin"
                class="buttonh"
                @click="logOut"
            >
                Çıxış                 

            </v-btn>
        </div>

        <div class="hidden-md-and-up">
        <v-menu :close-on-content-click="false"  offset-y>
        <template v-slot:activator="{ on, attrs }">
            <v-app-bar-nav-icon  
            v-bind="attrs"
            v-on="on"
            class="white--text no-back"
            ></v-app-bar-nav-icon>
        </template>
        <v-list>
            <v-list-item
            to="#head"
            v-scroll-to="'#head'"
            >
            <v-list-item-title>ƏSAS SƏHİFƏ</v-list-item-title>
            </v-list-item>
            <v-list-item
            href="/rules"
            >
            <v-list-item-title>LOTEREYA ŞƏRTLƏRİ</v-list-item-title>
            </v-list-item>
            <v-list-item
            v-for="(item, index) in items"
            :key="index"
            :to="item.to"
            v-scroll-to="item.to"
            >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
            <v-list-item
            :to="'/login'"
            v-if="!isLogin"
            >
            <v-list-item-title>GİRİŞ/QEYDİYYAT</v-list-item-title>
            </v-list-item>
            <v-list-item
            :to="'/profil'"
            v-if="isLogin"
            >
            <v-list-item-title>ŞƏXSİ KABİNET</v-list-item-title>
            </v-list-item>
            <v-list-item
            v-if="isLogin"
            @click="logOut"
            >
            <v-list-item-title>Çıxış</v-list-item-title>
            </v-list-item>
            <!-- <v-list-item>
                <v-select
                v-model="lang"
                @change="handleChange"
                class="header_link d-inline-block"
                style="width:80px;"
                background-color="#0B0909"
                color="#fff"
                item-color="indigo"
                :items="['az','ru']"
                label="DİL"
                solo
                dark
                ></v-select>
            </v-list-item> -->
        </v-list>
        </v-menu>
        </div>
    </v-app-bar>
</template>
<script>
    import user from '../../store/user'
    import i18n from '@/plugins/i18n'
    import {
    mdiExitToApp
  } from '@mdi/js'
      export default {
        data: () => ({
            icons: {
                mdiExitToApp

            },
            items: [
                { title: "Sual-Cavab", to: "#faq" },
                { title: "QALİBLƏR", to: "#qalibler" },
            ],
            isLogin: false,
            lang: 'az'
        }),
        methods: {
            logOut(){
                this.loading = true

                user.dispatch('updateLogin',{user:'', expires:-1})

                this.loading = false
                location.href='/'
                //  this.$router.push('/login')
            },
            handleChange(e){
                localStorage.setItem('lang',this.lang);
                i18n.locale = this.lang;
            }
        },
        created(){
            this.isLogin = user.getters.is_login;
            if(localStorage.getItem('lang')){
                this.lang = localStorage.getItem('lang');
            }
            else {
                localStorage.setItem('lang', 'az');
                this.lang = 'az';
            }
        }
        
    }
    </script>
    <style>
        @import url('../../assets/css/custom.css');
    </style>