<template>
    <v-main class="back-img d-inline-block" theme="light">
      <Header2/>
      <v-container
        class="no-padding all-page"
        fluid
      >
        <v-row
        class="login-row"
        >
          <!-- <v-col
            cols="12"
            sm="0"
            md="6"
            class="d-none d-md-flex no-padding"
          >
          <img src="@/assets/images/kinder present.png" alt="Kinder" class="max-width heith70">
          </v-col> -->
          <v-col
            cols="12"
            sm="12"
            md="12"
            class="no-padding justify-center d-flex  align-self-center"
          >
            <v-card 
            class="px-5 mx-10 login-cart justify-center"
            >
              <v-card-text>
                <v-form @submit.prevent="signIn" id="sign" ref="form">
                  <v-text-field
                    class="mb-3 design-color"
                    label="Mobil nömrə"
                    v-model="username"
                    :rules='rules.tel'
                    type="text"
                    outlined
                    color="#469BDC"
                    text-color="#469BDC"
                  ></v-text-field>
                  <v-text-field
                    label="Şifrə"
                    v-model="password"
                    :rules="rules.password"
                    type="password"
                    outlined
                    color="#469BDC"
                    text-color="#469BDC"
                  ></v-text-field>
                </v-form>
                <v-btn class="text-none button-text"
                  @click="sendPass"
                 text>
                  Şifrəni unutdum
                </v-btn>
              </v-card-text>
              <v-card-actions>
                  <v-spacer></v-spacer>
                <v-btn  
                class="px-10 buttonh"
                form="sign"
                :loading="loading"
                dark
                rounded
                type="submit"
                >DAXİL OL</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
              <!-- <v-row class="mt-10">
                <v-spacer></v-spacer>
                <v-btn class="px-10 buttonhh"
                  to="/register"
                  text>
                  QEYDİYYATDAN KEÇ
                </v-btn>
                <v-spacer></v-spacer>
              </v-row> -->
            </v-card>
          </v-col>
        </v-row>
        <!-- <div class="text-center mb-10">
            <img src="@/assets/images/Group.png" alt="Kinder" class="btm-img">
          </div> -->
      </v-container>
    </v-main>
</template>
<script>
  import axios from "axios"
  import store from '../store/'
  import user from '../store/user'
import Header2 from "./parts/Header2.vue"
  export default {
    props: {},
    data: () => ({
        username: "",
        password: "",
        loading: false,
        rules: {
            username: [
                val => val != "" || "Doldurun!"
            ],
            password: [
                val => val != "" || "Doldurun!"
            ],
            tel: [
                val => val > 500000000 && val < 994999999999 || `Nümunə:0501234567`,
            ],
        }
    }),
    methods: {
        signIn() {
            if (this.$refs.form.validate()) {
                if (994000000000 > Number(this.username)) {
                    this.username = 994000000000 + Number(this.username);
                }
                this.loading = true;
                axios.post(store.getters.getUrl + "user/login", { msisdn: this.username.toString(), password: this.password })
                    .then(response => {
                    if (response.data.success) {
                        // this.$toast.success("Success");
                        user.dispatch("updateLogin", { expires: 1, user: response.data.value });
                        this.loading = false;
                        this.$router.push("/profil");
                        // this.$router.push('/')
                    }
                    else {
                        this.$toast.error("Mobil nömrə və ya şifrə səhvdir!");
                        this.loading = false;
                    }
                });
            }
            else {
                this.$toast.error("Xanalar boşdur!");
                this.loading = false;
            }
        },
        sendPass() {
            if (this.username) {
                if (994000000000 > Number(this.username)) {
                    this.username = 994000000000 + Number(this.username);
                }
                this.loading = true;
                axios.get(store.getters.getUrl + "user/sendpassword?msisdn=" + this.username)
                    .then(response => {
                    if (response.data.success) {
                        this.$toast.success("Şifrəniz email ilə göndərildi.");
                    }
                    else {
                        this.$toast.error("Bu nömrə ilə qeydiyyat mövcud deyil.");
                        this.loading = false;
                    }
                });
            }
            else {
                this.$toast.error("Mobil nömrənizi daxil edin");
                this.loading = false;
            }
        },
    },
    computed: {
        formIsValid() {
            if (this.username == "")
                return false;
            if (this.password == "")
                return false;
            return true;
        }
    },
    components: { Header2 }
}
</script>
<style>
  @import url('../assets/css/custom.css');
.login-cart{
  width: 650px;
}
.login-row{
  width: 100%!important;
  height: 100%;
}
.all-page{
  width: 100%;
  height: 100%;
}
</style>