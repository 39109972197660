<template>
    <v-main>
      <div class="menu2" id="lotereya">
        <Header2/>

          <!-- ------------------------------------------------------------ -->

        <v-container>
          <div class="menus" style="min-height:600px">
            <div v-html="html_data">

            </div>

          </div>
        </v-container>

      </div>
      <div class="footer"></div>
    </v-main>
</template>
<script>
  import Header2 from "../parts/Header2.vue";
  export default {
    data: () => ({
      html_data:''
    }),
    methods: {

      onLoaded(){
        fetch('/rulesss.html', {responseEncoding: 'utf-8'})
        .then(response => response.arrayBuffer())
        .then(buffer => {
          const data = new TextDecoder("utf-8").decode(buffer);
          this.html_data = data
        })
      },


    },
    created(){
      this.onLoaded();
    },

    components: { Header2 }
}
</script>
<style>
@import url('../../assets/css/custom.css');

</style>