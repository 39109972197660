<template>
    <v-main>
      <div class="menu2" id="lotereya">
        <Header2/>

          <!-- ------------------------------------------------------------ -->
          <v-row justify="center">
        <v-dialog
          v-model="isError"
          max-width="600px"
        >
          <v-card class="notification-card">
            <v-card-text>
              <br>
              <br>
              <v-container class="text-center">
                <img class="notification-img" src="@/assets/images/errorimg.png" alt="Kinder">
                <br>
                <br>
                <p class="notification-head-error-text">Təəssüf ki, müraciətiniz qeydə alınmadı!</p>
                <p class="notification-body-text">{{ errorText }}</p>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>

      <v-row justify="center">
        <v-dialog
          v-model="isSuccess"
          max-width="600px"
        >
          <v-card class="notification-card">
            <v-card-text>
              <br>
              <br>
              <v-container class="text-center">
                <img class="notification-img" src="@/assets/images/successimg.png" alt="Kinder">
                <br>
                <br>
                <p class="notification-head-success-text">Təbriklər, müraciətiniz qeydə alındı!</p>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>

        <v-container>
          <div class="menus" style="min-height:600px">
            <h1 class="tile7">{{ user.name+" "+user.surname }}</h1>
            <v-row class="pt-3">
              <v-col
              cols="12"
              lg="12"
              md="12"
              sm="12"
              class="pb-0 text-center mt-sm-9"
              >
                <v-simple-table class="profile">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center">
                          №
                        </th>
                        <th class="text-center">
                          QƏBZİN YÜKLƏNİLMƏ TARİXİ
                        </th>
                        <th class="text-center">
                          QEYDİYYAT KODU
                        </th>
                        <th class="text-center">
                          STATUS
                        </th>
                        <th class="text-center">
                          HƏDİYYƏ
                        </th>
                        <th class="text-center">
                          QƏBZİN İZLƏNMƏSİ
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item,i) in items"
                        :key="item.id"
                      >
                        <td>{{ i+1 }}</td>
                        <td>{{ date_format(item.createdAt) }}</td>
                        <td>{{ item.coupon }}</td>
                        <td v-if="item.status==0">Təsdiq edildi</td>
                        <td v-else-if="item.status==2">İmtina edildi</td>
                        <td v-else>Gözləmədə</td>
                        <td>{{ item.prize}}</td>
                        <td>
                          <v-btn
                            class="button"
                            :href="'https://monitoring.e-kassa.gov.az/#/index?doc='+item.fiscalId"
                            target="_blank"
                            >
                              <v-icon>{{icons.mdiEye}}</v-icon>
                            </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>

              </v-col>
            </v-row>
          </div>
        </v-container>


          <!-- ----------------------------------------------------------------- -->
        <div class="menu5 d-flex flex-column align-center py-5">
          <h1 class="tile6"> YENİ QƏBZ YÜKLƏ</h1>
          <!-- QR reader -->
          <div class="qrReaderContainer">
            <qrcode-stream
              @decode="onDecode"
              @loaded="onLoaded"
            />
          </div>

          <v-card elevation="0" class="px-5 mx-16 fiscalContainer login-cart justify-center">
            <v-card-text>
              <v-form @submit.prevent="submitFiscalID" id="sign" ref="form">
                <!-- Fiscal ID -->
                <v-text-field
                  class="mb-3 form_field"
                  :label="'Fiscal ID'"
                  v-model="fiscalID"
                  :rules='rules.fiscalID'
                  type="text"
                  outlined
                  dark
                  color="#FFF"
                  text-color="#FFF"
                ></v-text-field>
              </v-form>
            </v-card-text>
      
            <v-card-actions>
              <v-spacer></v-spacer>
              <!-- Submit button -->
              <v-btn
              class="px-10 buttonh"
              form="sign"
              :loading="loading"
              type="submit"
              >İRƏLİ</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </div>
      </div>

    </v-main>
</template>
<script>
  import {
    mdiEye,
  } from '@mdi/js'
  import axios from "axios";
  import moment from "moment"
  import user from "../../store/user"
  import store from "../../store/index"
  import Header2 from "../parts/Header2.vue";
  import i18n from "@/plugins/i18n"
  export default {
    data: () => ({
      icons: {
            mdiEye,
        },
      user:{},
      items: [],
      rules: {},
      fiscalID: "",
      loading: false,
      isError: false,
      isSuccess: false,
      errorText: '',
      config: {
            headers: {
                Authorization: ""
            }
        },
    }),
    methods: {
      onDecode(result) {
        this.fiscalID = this.extractFiscalID(result)
      },
      onLoaded(){
        console.log('ok')
      },
      extractFiscalID(website) {
        // Fiscal ID is the next 12 characters after doc=
        const startIndex = website.indexOf("doc=") + 4
        const fiscalResult = website.substring(startIndex, startIndex + 12)
        return fiscalResult
      },
      submitFiscalID() {
        if (this.$refs.form.validate()) {
          this.loading = true
          axios.post(store.getters.getUrl + "Receipt/Add", { FiscalId: this.fiscalID }, this.config)
          .then(response => {
            if (response.data.success) {
              this.loading = false
              this.isSuccess = true;
              // Reloading after 2 seconds
              setTimeout(() => {
                location.reload()
              }, 2000)
            }
            else {
              if(response.data.errors[0].errorMsg == 'receipt_already_exists'){
                // this.$toast.error(i18n.t("fiscal_error2"));
                this.isError = true;
                this.errorText = i18n.t("fiscal_error2")
                this.loading = false;
              }
              else {
                // this.$toast.error(i18n.t("fiscal_error"));
                this.loading = false;
                this.isError = true;
                this.errorText = "Yüklədiyiniz qəbz lotereya şərtlərinə uyğun deyil, zəhmət olmasa lotereya şərtlərini oxuyub təkrar cəhd edin."
              }

            }
          }).catch((e)=>{
            console.log(e)
            this.$toast.error(i18n.t("fiscal_error2"));
            this.loading = false
          })
        }
        else {
          this.$toast.error(i18n.t('register_wrong_filling'));
          this.loading = false;
        }
        this.loading = false
      },
      date_format(k){
        return k ? moment(k).format('DD.MM.Y HH:mm:ss') : '';
      }
    },
    created(){
      this.config.headers.Authorization = "Bearer " + user.getters.getToken;
      navigator.permissions.query({ name: "camera" }).then(res => {
          if(res.state == "denied"){
            this.$toast.info("Kameraya çəkmə icazəsi verin.");
          }
      });
      axios.get(store.getters.getUrl+'user/userinfo',this.config)
          .then(response => {
            if(response.data.success){
              this.user = response.data.value
            }
            else {
              this.$toast.error('Xəta var!')
              this.loading = false
            }
          })
      axios.get(store.getters.getUrl+'receipt/AllByUser',this.config)
          .then(response => {
            if(response.data.success){
              this.items = response.data.value
            }
            else {
              this.$toast.error('Xəta var!')
              this.loading = false
            }
          })
          this.rules = {
            fiscalID: [
              val => val !== "" || i18n.t('register_error_please_fill'),
              val => val.length === 12 || i18n.t('fiscalEnter_error_length_not_enough')
            ]
          }
    
    },

    components: { Header2 }
}
</script>
<style>
@import url('../../assets/css/custom.css');
.button-container {
  display: none !important;
}
.qrReaderContainer{
  max-width: 400px;
  width: 80%
}
</style>