
<template>
  <v-app id="inspire">
    

<router-view/>
<v-btn
            v-scroll="onScroll"
            v-show="fab"
            fab
            dark
            fixed
            bottom
            right
            class="button"
            @click="toTop"
          >
            <v-icon>keyboard_arrow_up</v-icon>
          </v-btn>
  </v-app>
</template>

<script>
    import {
    mdiAccount,
  } from '@mdi/js'
import user from '../store/user'
  export default {
    props: {
      // source: String,
    },
    data: () => ({
      icons: {
        mdiAccount,

      },
      fab: false

    }),
    methods:{
      onScroll (e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset ||   e.target.scrollTop || 0
      this.fab = top > 20
      },
      toTop () {
        this.$vuetify.goTo(0)
      }
    },
    created(){
      // this.user = user.getters.getUser
    },
  }
</script>