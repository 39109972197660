import Vue from "vue"
import VueRouter from "vue-router"
import Dashboard from './components/Dashboard'
import Index from './components/dashboard/Index'
import VueMeta from 'vue-meta'
import DatetimePicker from 'vuetify-datetime-picker'
import guest from './middleware/guest'
import auth from './middleware/auth'
import store from './store/user'
import middlewarePipeline from './middlewarePipeline'
import Login from './components/Login'
import Register from './components/Register'
import Profil from './components/dashboard/Profil'
import ChangePassword from './components/dashboard/ChangePassword'
import Check from './components/dashboard/Check'
import Rules from './components/dashboard/Rules'
import Mexfi from './components/dashboard/Mexfi'
Vue.use(VueMeta)
Vue.use(VueRouter)
Vue.use(DatetimePicker)
export const router = new VueRouter({
    routes : [
        
        // {
        //     path : '/login',
        //     component : Login,
        //     name : 'login',
        //     meta : {
        //         title : 'Giriş Paneli',
        //         middleware: [
        //             guest,
        //         ]
        //     }
        // },
        {
            path : '/',
            component : Dashboard,
            children: [
                {
                    path: '/',
                    component: Index,
                    name : 'dashboard',
                    meta : {
                        title : 'Kinder',
                        // middleware: [
                        //     auth,
                        // ]
                    },


                },
                {
                    path: '/profil',
                    component: Profil,
                    name : 'profil',
                    meta : {
                        title : 'Kinder',
                        middleware: [
                            auth,
                        ]
                    },


                },
                {
                    path: '/rules',
                    component: Rules,
                    name : 'rules',
                    meta : {
                        title : 'Kinder',
    
                    },


                },
                {
                    path: '/mexfi',
                    component: Mexfi,
                    name : 'mexfi',
                    meta : {
                        title : 'Kinder',
    
                    },


                },
                {
                    path: '/changepassword',
                    component: ChangePassword,
                    name : 'chpassword',
                    meta : {
                        title : 'Kinder',
                        middleware: [
                            auth,
                        ]
                    },


                },
                {
                    path: '/check',
                    component: Check,
                    name : 'check',
                    meta : {
                        title : 'Kinder',
                        middleware: [
                            auth,
                        ]
                    },


                },
                {
                    path: '/login',
                    component: Login,
                    name : 'login',
                    meta : {
                        title : 'Kinder',
                        middleware: [
                            guest,
                        ]
                    },


                },
                {
                    path: '/register',
                    component: Register,
                    name : 'register',
                    meta : {
                        title : 'Kinder',
                        middleware: [
                            guest,
                        ]
                    },


                },
            ],
        },
        {
            path : '*',
            redirect : '/'
        }
    ],
    mode : "history"
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title
    if (!to.meta.middleware) {
        return next()
    }
    const middleware = to.meta.middleware

    const context = {
        to,
        from,
        next,
        store
    }
    return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1)
    })

  })