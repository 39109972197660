<template>
    <v-main>
      <div class="menu2" id="lotereya">
        <Header2/>

          <!-- ------------------------------------------------------------ -->

        <v-container>
          <div class="menus" style="min-height:600px">
            <div>
              <br>
              <br>
              <p style='margin-top:3.15pt;margin-right:0cm;margin-bottom:.0001pt;margin-left:5.0pt;text-align:justify;font-size:21px;font-family:"Times New Roman",serif;font-weight:bold;'><span style='font-family:"Helvetica",sans-serif;'>MƏXFİLİK&nbsp;SİYASƏTİ</span></p>
<p style='margin-top:13.45pt;margin-right:5.9pt;margin-bottom:.0001pt;margin-left:5.0pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;line-height:102%;'><strong><span style='font-family:"Helvetica",sans-serif;'>&ldquo;CREO&rdquo; MMC&nbsp;</span></strong><span style='font-family:"Helvetica",sans-serif;'>olaraq şəxsi məlumatlarınızın necə istifadə olunduğu və paylaşıldığına əhəmiyyət verdiyinizi bilir və sizin məxfiliyinizə ciddi yanaşırıq.</span></p>
<p style='margin-top:7.1pt;margin-right:5.9pt;margin-bottom:.0001pt;margin-left:5.0pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;line-height:100%;'><strong><span style='font-family:"Helvetica",sans-serif;'>&quot;CREO&quot; MMC, &quot;kinderpromo.az&quot;&nbsp;</span></strong><span style='font-family:"Helvetica",sans-serif;'>veb-saytını (&quot;veb-səhifə&quot;) idarə edir. Bu &ldquo;Məxfilik Siyasəti&rdquo; veb-səhifəmiz vasitəsilə bizə təqdim etdiyiniz hər hansı bir şəxsi məlumatın&nbsp;toplanması, istifadəsi və &uuml;&ccedil;&uuml;nc&uuml; şəxslərlə b&ouml;l&uuml;ş&uuml;lməsi ilə bağlı metodlar barədə sizə məlumat vermək &uuml;&ccedil;&uuml;n nəzərdə tutulmuşdur. &quot;Şəxsi Məlumat&quot; &ndash; sizin adınız, elektron po&ccedil;t &uuml;nvanınız, telefon n&ouml;mrəniz, eləcə də yuxarıda g&ouml;stərilənlərlə əlaqəli digər qeyri-ictimai məlumatlarınız ola bilər.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-size:17px;font-family:"Helvetica",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:.05pt;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-size:17px;font-family:"Helvetica",sans-serif;'>&nbsp;</span></p>
<h1 style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:5.0pt;font-size:16px;font-family:"Times New Roman",serif;font-style:italic;text-decoration:underline;'><u><span style='font-family:"Helvetica",sans-serif;'>İSTİFADƏ&Ccedil;İ RAZILIĞI</span></u></h1>
<p style='margin-top:7.6pt;margin-right:5.95pt;margin-bottom:.0001pt;margin-left:5.0pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;line-height:100%;'><span style='font-family:"Helvetica",sans-serif;'>Veb-səhifəmizə daxil olaraq və ya başqa şəkildə ondan istifadə edərək, bu &ldquo;Məxfilik Siyasəti&rdquo;- nin şərtlərinə razılıq vermiş və şəxsi məlumatlarınızın bu &ldquo;Məxfilik Siyasəti&rdquo;-nə uyğun istifadəsinə birbaşa razılıq vermiş hesab olunursunuz. Şəxsi məlumatlarınız Azərbaycan Respublikasının qanunlarına uyğun olaraq <strong>&quot;CREO&quot; MMC&nbsp;</strong>tərəfindən emal edilə bilər.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-size:17px;font-family:"Helvetica",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:.35pt;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-size:17px;font-family:"Helvetica",sans-serif;'>&nbsp;</span></p>
<h1 style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:5.0pt;font-size:16px;font-family:"Times New Roman",serif;font-style:italic;text-decoration:underline;'><u><span style='font-family:"Helvetica",sans-serif;'>ŞƏXSİ&nbsp;MƏLUMATLARIN TOPLANMASI</span></u></h1>
<p style='margin-top:7.55pt;margin-right:0cm;margin-bottom:.0001pt;margin-left:5.0pt;font-size:16px;font-family:"Times New Roman",serif;line-height:100%;'><span style='font-family:"Helvetica",sans-serif;'>Veb-səhifəmiz&nbsp;vasitəsi&nbsp;ilə&nbsp;bizə&nbsp;k&ouml;n&uuml;ll&uuml;&nbsp;olaraq&nbsp;təqdim&nbsp;etdiyiniz&nbsp;şəxsi&nbsp;məlumatları&nbsp;toplayırıq. Toplaya biləcəyimiz şəxsi məlumat n&ouml;vləri bunlardır:</span></p>
<p style='margin-top:.1pt;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-family:"Helvetica",sans-serif;'>&nbsp;</span></p>
<ul style="list-style-type: undefined;">
    <li><span style='font-family:"Helvetica",sans-serif;'>Adlar</span></li>
    <li><span style='font-family:"Helvetica",sans-serif;'>Elektron&nbsp;po&ccedil;t&nbsp;&uuml;nvanı</span></li>
    <li><span style='font-family:"Helvetica",sans-serif;'>Telefon&nbsp;n&ouml;mrələri</span></li>
</ul>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-size:17px;font-family:"Helvetica",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:.3pt;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-size:17px;font-family:"Helvetica",sans-serif;'>&nbsp;</span></p>
<h1 style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:5.0pt;font-size:16px;font-family:"Times New Roman",serif;font-style:italic;text-decoration:underline;'><u><span style='font-family:"Helvetica",sans-serif;'>ŞƏXSİ&nbsp;MƏLUMATLARDAN İSTİFADƏ</span></u></h1>
<p style='margin-top:7.8pt;margin-right:5.95pt;margin-bottom:.0001pt;margin-left:5.0pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;line-height:100%;'><span style='font-family:"Helvetica",sans-serif;'>&Uuml;mumiyyətlə, bizə təqdim etdiyiniz şəxsi məlumatlar &ldquo;Məktəbə d&ouml;n&uuml;ş idmanla g&ouml;r&uuml;ş&rdquo; adlı stimullaşdırıcı lotereya &uuml;&ccedil;&uuml;n istifadə olunur və bitdiyi tarixdən 90 g&uuml;n sonra tamamən sistemdən silinəcəkdir. Topladığımız şəxsi məlumatları əlavə ictimai məlumat və ya digər &uuml;&ccedil;&uuml;nc&uuml; mənbələrin məlumatları ilə birləşdirə bilərik.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-size:17px;font-family:"Helvetica",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:.15pt;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-size:17px;font-family:"Helvetica",sans-serif;'>&nbsp;</span></p>
<h1 style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:5.0pt;font-size:16px;font-family:"Times New Roman",serif;font-style:italic;text-decoration:underline;'><u><span style='font-family:"Helvetica",sans-serif;'>ŞƏXSİ&nbsp;MƏLUMATLARIN A&Ccedil;IQLANMASI</span></u></h1>
<p style='margin-top:7.8pt;margin-right:5.85pt;margin-bottom:.0001pt;margin-left:5.0pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-family:"Helvetica",sans-serif;'>&ldquo;Şəxsi məlumatlar&rdquo;-ınızın bir hissəsini və ya hamısını Tərəfdaşlarımıza &ouml;t&uuml;rə bilərik, bu halda biz &ouml;z tərəfdaşlarımızdan bu &ldquo;Məxfilik Siyasəti&rdquo;-nə riayət etməklərini tələb edəcəyik. Şirkətimiz və ya hissələrimiz başqa bir şirkət tərəfindən əldə edilərsə, həmin şirkət topladığımız &ldquo;Şəxsi Məlumatlar&rdquo;-a sahib olacaq və bu şirkət hazırki &ldquo;Məxfilik Siyasəti&rdquo;-nə uyğun olaraq &ldquo;Şəxsi Məlumatlar&rdquo;-la bağlı h&uuml;quq və &ouml;hdəlikləri &ouml;z &uuml;zərinə g&ouml;t&uuml;rəcəkdir.</span></p>
<p><span style='font-size:16px;font-family:"Helvetica",sans-serif;'><br>&nbsp;</span></p>
<p style='margin-top:3.5pt;margin-right:5.95pt;margin-bottom:.0001pt;margin-left:5.0pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-family:"Helvetica",sans-serif;'>Biz təqdim etdiyimiz xidmətlərin bir və ya bir ne&ccedil;ə aspektini asanlaşdırmaq və ya &ouml;t&uuml;rmək &uuml;&ccedil;&uuml;n &uuml;&ccedil;&uuml;nc&uuml; şəxslərdən istifadə edə bilərik və bu səbəbdən də bəzi şəxsi məlumatları birbaşa bu xidmət təminat&ccedil;ıları ilə paylaşa bilərik. Bu xidmət təminat&ccedil;ıları bizimlə məxfilik haqda sazişlər bağlayır, hansı ki, tələb olunan xidmətin g&ouml;stərilməsi xaricində istifadə etdiyimiz məlumatlarınızın istifadəsini və a&ccedil;ıqlanmasını qadağan edir.</span></p>
<p style='margin-top:.35pt;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-size:25px;font-family:"Helvetica",sans-serif;'>&nbsp;</span></p>
<h1 style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:5.0pt;font-size:16px;font-family:"Times New Roman",serif;font-style:italic;text-decoration:underline;'><u><span style='font-family:"Helvetica",sans-serif;'>YAŞ HƏDDİ</span></u></h1>
<p style='margin-top:7.8pt;margin-right:5.9pt;margin-bottom:.0001pt;margin-left:5.0pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;line-height:100%;'><span style='font-family:"Helvetica",sans-serif;'>Veb-səhifəmiz 18 yaşdan ki&ccedil;ik uşaqlar &uuml;&ccedil;&uuml;n nəzərdə tutulmayıb. Biz bilərəkdən bu yaş qrupundakı istifadə&ccedil;ilər haqqında şəxsi məlumat toplamırıq.</span></p>
<p style='margin-top:.3pt;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-size:25px;font-family:"Helvetica",sans-serif;'>&nbsp;</span></p>
<h1 style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:5.0pt;font-size:16px;font-family:"Times New Roman",serif;font-style:italic;text-decoration:underline;'><u><span style='font-family:"Helvetica",sans-serif;'>DİGƏR&nbsp;VEB-SƏHİFƏLƏRƏ Y&Ouml;NLƏNƏN LİNKLƏR</span></u></h1>
<p style='margin-top:7.8pt;margin-right:5.9pt;margin-bottom:.0001pt;margin-left:5.0pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-family:"Helvetica",sans-serif;'>Bizim,&nbsp;sizin&nbsp;rahatlığınız&nbsp;&uuml;&ccedil;&uuml;n,&nbsp;hər&nbsp;hansı&nbsp;digər&nbsp;məkan&nbsp;və&nbsp;ya&nbsp;veb-səhifəyə&nbsp;link&nbsp;təqdim&nbsp;etməyimiz&nbsp;o demək deyil ki, bu məkan və ya veb-səhifəni və ya onun məzmununu dəstəkləyirik. Biz &uuml;&ccedil;&uuml;nc&uuml; tərəflərin veb-səhifələrinə və onların məzmununa nəzarət etmirik və onlara cavabdeh deyilik. Nəzərə alın ki, &ldquo;Məxfilik Siyasəti&rdquo;-mizin şərtləri &uuml;&ccedil;&uuml;nc&uuml; tərəflərin veb-səhifələrinə şamil&nbsp;edilmir.</span></p>
<h1 style='margin-top:8.3pt;margin-right:0cm;margin-bottom:.0001pt;margin-left:5.0pt;font-size:16px;font-family:"Times New Roman",serif;font-style:italic;text-decoration:underline;'><u><span style='font-family:"Helvetica",sans-serif;'>TƏHL&Uuml;KƏSİZLİK</span></u></h1>
<p style='margin-top:7.8pt;margin-right:5.9pt;margin-bottom:.0001pt;margin-left:5.0pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-family:"Helvetica",sans-serif;'>Biz&nbsp;şəxsi&nbsp;məlumatlarınızı&nbsp;icazəsiz&nbsp;giriş,&nbsp;istifadə&nbsp;və&nbsp;ya&nbsp;aşkara&nbsp;&ccedil;ıxarılmağının&nbsp;qarşısını&nbsp;almaq&nbsp;&uuml;&ccedil;&uuml;n bir sıra standart təhl&uuml;kəsizlik texnologiyaları və prosedurlarından istifadə edirik. Lakin <strong>&ldquo;kinderpromo.az&rdquo;&nbsp;</strong>da daxil olmaqla he&ccedil; bir şirkət şəxsi məlumatla əlaqəli təhl&uuml;kəsizlik&nbsp;risklərini tamamilə aradan qaldıra bilməz.</span></p>
<h1 style='margin-top:8.15pt;margin-right:0cm;margin-bottom:.0001pt;margin-left:5.0pt;font-size:16px;font-family:"Times New Roman",serif;font-style:italic;text-decoration:underline;'><u><span style='font-family:"Helvetica",sans-serif;'>ŞƏXSİ&nbsp;MƏLUMATIN NƏZƏRDƏN KE&Ccedil;İRİLMƏSİ VƏ YENİDƏN BAXILMASI</span></u></h1>
<p style='margin-top:7.8pt;margin-right:5.95pt;margin-bottom:.0001pt;margin-left:5.0pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;line-height:100%;'><span style='font-family:"Helvetica",sans-serif;'>Veb-səhifəmiz &ouml;z istifadə&ccedil;ilərinə, onlara məlumat g&ouml;ndərdiyimiz anda, bizdən məktub almaqdan imtina etmək imkanı verir. Səhifəmiz sizə bizdən bir daha məktub almamağınız və ya xidmətlərimizi qəbul etməməyiniz &uuml;&ccedil;&uuml;n məlumatlarınızı verilənlər bazamızdan silmək imkanı verir. Qeydiyyatdan ke&ccedil;miş istifadə&ccedil;ilər daha əvvəl daxil olunmuş məlumatları dəyişdirmək&nbsp;&uuml;&ccedil;&uuml;n &ldquo;Mənim Hesabım&rdquo; səhifəsinə daxil ola və <strong>&ldquo;CREO&rdquo;MMC-</strong>dan gələn e-po&ccedil;t bildirişləri &uuml;&ccedil;&uuml;n əlaqə məlumatlarını dəyişə bilərlər.</span></p>
<h1 style='margin-top:7.35pt;margin-right:0cm;margin-bottom:.0001pt;margin-left:5.0pt;font-size:16px;font-family:"Times New Roman",serif;font-style:italic;text-decoration:underline;'><u><span style='font-family:"Helvetica",sans-serif;'>BİZİMLƏ&nbsp;ƏLAQƏ</span></u></h1>
<p style='margin-top:7.8pt;margin-right:5.95pt;margin-bottom:.0001pt;margin-left:5.0pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;line-height:102%;'><span style='font-family:"Helvetica",sans-serif;'>Bu &ldquo;Məxfilik Siyasəti&rdquo; ilə bağlı hər hansı bir sualınız və ya ş&uuml;bhəniz varsa, &ldquo;ƏLAQƏ&rdquo; b&ouml;lməsində g&ouml;stərilən əlaqə məlumatlarını istifadə edərək bizimlə əlaqə saxlaya bilərsiniz.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-size:17px;font-family:"Helvetica",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:.4pt;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'><span style='font-family:"Helvetica",sans-serif;'>&nbsp;</span></p>
<h1 style='margin-top:.05pt;margin-right:0cm;margin-bottom:.0001pt;margin-left:5.0pt;font-size:16px;font-family:"Times New Roman",serif;font-style:italic;text-decoration:underline;'><u><span style='font-family:"Helvetica",sans-serif;'>MƏXFİLİK SİYASƏTİNİN YENİLƏNMƏSİ</span></u></h1>
<p style='margin-top:7.8pt;margin-right:5.95pt;margin-bottom:.0001pt;margin-left:5.0pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style='font-family:"Helvetica",sans-serif;'>Bu &ldquo;Məxfilik Siyasəti&rdquo; vaxtaşırı nəzərdən ke&ccedil;irilə bilər. Şəxsi məlumatlarınızı necə istifadə etdiyimizə dair hər hansı bir dəyişiklik edilərsə, bu haqda veb-səhifəmizdə xəbərdarlıq yerləşdirəcəyik. Bu dəyişikliklərlə razı olmadığınız təqdirdə, veb-səhifəmizdən istifadəni dayandırmalısınız. Bu dəyişikliklər barədə bildiriş aldıqdan sonra veb-səhifəmizdən istifadəyə davam etməyiniz, bu dəyişiklikləri və dəyişikliklərin şərtlərini qəbul etdiyinizi g&ouml;stərəcək.</span></p>
            </div>

          </div>
        </v-container>

      </div>
      <div class="footer"></div>
    </v-main>
</template>
<script>
  import Header2 from "../parts/Header2.vue";
  export default {
    data: () => ({
      html_data:''
    }),
    methods: {

      onLoaded(){
        fetch('https://kinderpromo.az/rules.html', {responseEncoding: 'ISO-8859-1'})
        .then(response => response.arrayBuffer())
        .then(buffer => {
          const data = new TextDecoder("ISO-8859-1").decode(buffer);
          this.html_data = data
          console.log(data)
        })
      },


    },
    created(){
      this.onLoaded();
    },

    components: { Header2 }
}
</script>
<style>
@import url('../../assets/css/custom.css');

</style>