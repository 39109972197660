<template>
    <v-main class="menu2  back-img" theme="light">
      <Header2/>
      <v-container
        class="no-padding all-page"
        fluid
      >
      <v-row justify="center">
        <v-dialog
          v-model="isError"
          max-width="600px"
        >
          <v-card class="notification-card">
            <v-card-text>
              <br>
              <br>
              <v-container class="text-center">
                <img class="notification-img" src="@/assets/images/errorimg.png" alt="Kinder">
                <br>
                <br>
                <p class="notification-head-error-text">Təsdiq kodu yanlışdır. Zəhmət olmasa təkrar cəhd edin!</p>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
      <v-row justify="center">
        <v-dialog
          v-model="isSuccess"
          max-width="600px"
        >
          <v-card class="notification-card">
            <v-card-text>
              <br>
              <br>
              <v-container class="text-center">
                <img class="notification-img" src="@/assets/images/successimg.png" alt="Kinder">
                <br>
                <br>
                <p class="notification-head-success-text">Uğurlu qeydiyyat!</p>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
      <v-row justify="center">
        <v-dialog
          v-model="dialog"
          persistent
          max-width="600px"
        >
          <v-card class="notification-card">
            <br>
            <v-card-title>
              <span class="text-h5 notification-head-success-text">Sizə sms ilə göndərdiyimiz təsdiq kodunu daxil edin</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="2"></v-col>
                  <v-col cols="8">
                    <v-text-field
                      class="design-color"
                      label="Təstiq üçün şifrə*"
                      outlined
                      color="#469BDC"
                      text-color="#469BDC"
                      :rules='rules.otp'
                      v-model="otp"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2"></v-col>
                </v-row>

                <v-row>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="buttonh mr-3"
                    @click="dialog = false"
                  >
                    GERİ
                  </v-btn> 

                  <v-btn
                    color="buttonblueh buttonh"
                    @click="verifyOTP"
                  >
                    İRƏLİ
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-row>

              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-container>
                <v-row>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="buttonh1"
                    @click="sendPass"
                  >
                   KODU YENİDƏN GÖNDƏR
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-row>
              </v-container>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
        <v-row
        class="login-row"
        >
          <v-col
            cols="12"
            sm="12"
            md="12"
            class="no-padding d-none d-md-flex  justify-center d-flex  align-self-center"
          >
            <v-card 
            class="px-5 mx-10 login-cart justify-center"
            >
              <v-card-text>
                <v-form @submit.prevent="register" id="sign" ref="form">
                  <v-text-field
                    class="mb-3 design-color"
                    label="Ad *"
                    v-model="request.name"
                    :rules='rules.req'
                    type="text"
                    outlined
                    color="#469BDC"
                    text-color="#469BDC"
                  ></v-text-field>
                  <v-text-field
                    class="mb-3 design-color"
                    label="Soyad *"
                    v-model="request.surname"
                    :rules='rules.req'
                    type="text"
                    outlined
                    color="#469BDC"
                    text-color="#469BDC"
                  ></v-text-field>
                  <v-text-field
                    class="mb-3 design-color"
                    label="Mobil nömrə *"
                    v-model="request.msisdn"
                    :rules='rules.tel'
                    type="text"
                    outlined
                    color="#469BDC"
                    text-color="#469BDC"
                  ></v-text-field>
                  <v-text-field
                    class="mb-3 design-color"
                    label="Email *"
                    v-model="request.email"
                    :rules='rules.email'
                    type="text"
                    outlined
                    color="#469BDC"
                    text-color="#469BDC"
                  ></v-text-field>
                  <v-text-field
                    label="Şifrə *"
                    v-model="request.password"
                    :rules="rules.password"
                    type="password"
                    outlined
                    color="#469BDC"
                    text-color="#469BDC"
                  ></v-text-field>
                  <v-text-field
                    label="Təkrar şifrə *"
                    v-model="request.confirmPassword"
                    :rules="rules.req"
                    type="password"
                    outlined
                    color="#469BDC"
                    text-color="#469BDC"
                  ></v-text-field>
                  <v-checkbox
                    v-model="check2"
                    class="check"
                    label="18 yaşımın tamam olduğunu təsdiq edirəm."
                    color="info"
                  ></v-checkbox>
                  <v-checkbox
                    v-model="check"
                    class="check"
                    label="Lotereyanın şərtləri ilə tanış oldum, qəbul edirəm."
                    color="info"
                  ></v-checkbox>
                  <v-checkbox
                    v-model="check3"
                    class="check"
                    color="info"
                  >
                  <template v-slot:label>
                    <div>
                      Fərdi məlumatlardan istifadə qaydaları ilə tanış oldum, qəbul edirəm. 
                      <v-tooltip location="bottom">
                        <template v-slot:activator="{ props }">
                          <br>
                          <a
                            href="/mexfi"
                            target="_blank"
                            v-bind="props"
                            @click.stop
                          >
                          Məxfilik siyasəti
                          </a>
                        </template>
                        Opens in new window
                      </v-tooltip>
                    </div>
                  </template>
                </v-checkbox>
                </v-form>
              </v-card-text>
              <v-card-actions>
                  <v-spacer></v-spacer>
                <v-btn  
                class="px-10 buttonh"
                form="sign"
                :loading="loading"

                rounded
                :disabled="!ccc"
                type="submit"
                >QEYDİYYATDAN KEÇ</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
</template>
<script>
  import axios from "axios"
  import store from '../store'
  import user from '../store/user'
import Header2 from "./parts/Header2.vue"
  export default {
    props: {},
    data: () => ({
        request: {
            msisdn: "",
            password: "",
            confirmPassword: "",
            name: "",
            surname: "",
            email: ""
        },
        check: false,
        check2: false,
        check3: false,
        loading: false,
        dialog: false,
        isSuccess: false,
        isError: false,
        otp: '',
        rules: {
            req: [
                val => val != "" || "Xahiş edirik, * işarəsi olan sətirləri tam doldurasınız."
            ],
            password: [
                val => val != "" || "Xahiş edirik, * işarəsi olan sətirləri tam doldurasınız. ",
                val => val.length >= 6 || "Şifrəniz çox qısadır, ən azı 6 simvol olmalıdır"
            ],
            otp: [
            val => val > 999 && val < 10000 || `Nümunə:1234`,
            ],
            tel: [
                val => val > 500000000 && val < 994999999999 || `Nümunə:0501234567`,
                val => !val || /^[0-9]*\.?[0-9]*$/.test(val) || 'Nömrə düzgün formatda deyil'
            ],
            email: [
                val => val != "" || "Xahiş edirik, * işarəsi olan sətirləri tam doldurasınız.",
                val => !val || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(val) || " E-maili doğru şəkildə daxil edin."
            ],
        }
    }),
    methods: {
        register() {
            if (this.request.password != this.request.confirmPassword) {
                this.$toast.error("Şifrənin təkrarını doğru yazmadınız.");
            }
            else {
            if (this.$refs.form.validate()) {
                if (994000000000 > Number(this.request.msisdn)) {
                    this.request.msisdn = 994000000000 + Number(this.request.msisdn);
                }
                this.request.msisdn = this.request.msisdn.toString();
                this.loading = true;
                axios.post(store.getters.getUrl + "user/register", this.request)
                    .then(response => {
                    if (response.data.success) {
                        this.$toast.success("Nömrənizə şifrə göndərildi!");
                        // user.dispatch('updateLogin',{expires:1, user:response.data.data})
                        this.loading = false;
                        this.dialog = true;
                        // location.href='/'
                      
                    }
                    else {
                        this.$toast.error(response.data.errors[0].errorMsg);
                        this.loading = false;
                    }
                })
                .catch(e=> {
                  this.$toast.error("Xəta!");
                  this.loading = false;
                });
            }
            else {
                this.$toast.error("Xanalar düzgün doldurulmayıb!");
                this.loading = false;
            }
          }
        },
        verifyOTP(){
          if (this.otp.length == 4) {

                axios.post(store.getters.getUrl + "user/verifyotp", 
                {
                  "msisdn": this.request.msisdn.toString(),
                  "otpCode": this.otp
                }
                )
                    .then(response => {
                    if (response.data.success) {
                        // this.$toast.success("Uğurlu qeydiyyat!");
                        // user.dispatch('updateLogin',{expires:1, user:response.data.data})
                        this.dialog = false;
                        this.isSuccess = true;
                        // location.href='/'
                        setTimeout(() => {
                          this.$router.push("/login");
                        }, 2000)
                        
                    }
                    else {
                      
                        this.isError = true;
                        setTimeout(() => {
                          this.isError = false;
                        }, 2000)
                        this.loading = false;
                    }
                });
            }
            else {
                this.$toast.error("SMS şifrə düzgün doldurulmayıb!");
                this.loading = false;
            }
        },
        sendPass() {
            if (this.request.msisdn) {
                if (994000000000 > Number(this.request.msisdn)) {
                  this.request.msisdn = 994000000000 + Number(this.request.msisdn);
                }
                this.loading = true;
                axios.get(store.getters.getUrl + "user/resendotp?msisdn=" + this.request.msisdn)
                    .then(response => {
                    if (response.data.success) {
                        this.$toast.success("Nömrənizə şifrə göndərildi!");
                    }
                    else {
                        this.$toast.error("Mobil nömrə səhvdir!");
                        this.loading = false;
                    }
                    this.loading = false;
                });
                this.loading = false;
            }
            else {
                this.$toast.error("Nömrə boşdur!");
                this.loading = false;
            }
        },
    },
    computed: {
      ccc(){
        if(this.check)
          if(this.check2)
            if(this.check3)
              return true;
        return false;
      }
    },
    components: { Header2 }
}
</script>
<style>
  @import url('../assets/css/custom.css');
.login-cart{
  width: 650px;
}
.login-row{
  width: 100%!important;
  height: 100%;
}
.all-page{
  width: 100%;
  height: 100%;
}
</style>